import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Col, Form, Modal, Row, Dropdown, Spinner } from "react-bootstrap";
import { Edit, MoreVert, SwapVert } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import AdminLoader from "../../components/AdminLoader";
import { APICALL } from "../../../helper/api/api";
import { toastifyError, toastifySuccess } from "../../../helper/Utility";
import ConfirmationModal from "../../components/ConfirmationModal";

const Sites = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentSiteId, setCurrentSiteId] = useState(null);
  const { id, qtagNumber, siteId, registerId, registerName } =
    location.state || {};
  //   console.log("location.state", location.state);
  const handleShowModal = () => {
    setIsEditMode(false);
    setFormData({ siteName: "", registerId: [] });
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleRowToggle = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const [loading, setLoading] = useState(false);
  const [sites, setSites] = useState([]);

  const getSitesList = async () => {
    setLoading(true);
    try {
      const res = await APICALL("/admin/getInspectionHistoryById", "post", {
        id: id,
      });
      if (res?.status) {
        setLoading(false);
        setSites(res?.data);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const [registers, setRegisters] = useState([]);

  const getRegistersList = async () => {
    try {
      const res = await APICALL("/admin/getAllregister", "post", {
        type: "newsite",
      });
      if (res?.status) {
        const registerData = res?.data.map((data) => data);
        setRegisters(registerData);
      }
    } catch (error) {}
  };

  const navigateInspection = (siteId, registerId, registerName) => {
    navigate("/admin/inspection-list/", {
      state: {
        siteId: siteId,
        registerId: registerId,
        registerName: registerName,
      },
    });
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [searchTerm, setSearchTerm] = useState("");

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const filteredSites = sites.filter((site) =>
    Object.keys(site).some((key) =>
      site[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const sortedSites = [...filteredSites].sort((a, b) => {
    if (!sortConfig.key) return 0;

    const order = sortConfig.direction === "asc" ? 1 : -1;
    const valueA = a[sortConfig.key] || "";
    const valueB = b[sortConfig.key] || "";

    return valueA > valueB ? order : valueA < valueB ? -order : 0;
  });

  useEffect(() => {
    getSitesList();
    getRegistersList();
  }, []);

  const [formData, setFormData] = useState({
    siteName: "",
    registerId: [],
  });

  return (
    <>
      <Row>
        <Col md={6}>
          <div className="bredcrumb_img">
            <ul className="p-0">
              <li>
                <Link to="/admin/dashboard">
                  <i className="fa-solid fa-house me-2"></i>Home /
                </Link>
              </li>
              <li>
                <Link className="active" to="/admin/inspection-history">
                  Inspection History
                </Link>
              </li>
            </ul>
          </div>
          <Typography className="top_page" variant="h5">
            Inspection History of Tag: {qtagNumber}
          </Typography>
        </Col>
        <Col md={6} className="text-end">
          <div className="d-flex align-items-center justify-content-end">
            <div className="add_new">
              <Button
                className="global_btn"
                onClick={() =>
                  navigateInspection(siteId, registerId, registerName)
                }
              >
                Back
              </Button>
            </div>
          </div>
        </Col>
      </Row>

      <Paper className="table_samepattern mt-md-2 mt-3">
        <TableContainer>
          {loading ? (
            <AdminLoader />
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Inspector Name</TableCell>
                  <TableCell align="left">Result</TableCell>
                  <TableCell align="left">Register</TableCell>
                  <TableCell align="left">Inspection Date</TableCell>
                  <TableCell align="left">Next Inspection</TableCell>
                  <TableCell align="left">Failure Reason</TableCell>
                  <TableCell align="left">Plugs</TableCell>
                  <TableCell align="left">FlexibleCord</TableCell>
                  <TableCell align="left">Cover Guards</TableCell>
                  <TableCell align="left">IecLead Polarity</TableCell>
                  <TableCell align="left">Iec LeadContinuity</TableCell>
                  <TableCell align="left">Insulation Resistance</TableCell>
                  <TableCell align="left">Comment</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {sortedSites.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow
                      key={index}
                      onClick={() => handleRowToggle(index)}
                    >
                      <TableCell>{row?.inspectorName}</TableCell>
                      <TableCell>{row?.result}</TableCell>
                      <TableCell>{row?.registerId?.registerName}</TableCell>
                      <TableCell>
                        {row?.inspectionDate
                          ? new Date(row?.inspectionDate)
                              .toISOString()
                              .split("T")[0]
                          : "-"}
                      </TableCell>
                      <TableCell>
                        {row?.nextInspection
                          ? new Date(row?.nextInspection)
                              .toISOString()
                              .split("T")[0]
                          : "-"}
                      </TableCell>
                      <TableCell>{row?.failureReason}</TableCell>
                      <TableCell>{row?.electricalTests?.plugs}</TableCell>
                      <TableCell>
                        {row?.electricalTests?.flexibleCord}
                      </TableCell>
                      <TableCell>{row?.electricalTests?.coverGuards}</TableCell>
                      <TableCell>
                        {row?.electricalTests?.iecLeadPolarity}
                      </TableCell>
                      <TableCell>
                        {row?.electricalTests?.iecLeadContinuity?.value}-
                        {row?.electricalTests?.iecLeadContinuity?.result}
                      </TableCell>
                      <TableCell>
                        {row?.electricalTests?.insulationResistance?.value}-
                        {row?.electricalTests?.insulationResistance?.result}
                      </TableCell>
                      <TableCell>{row?.comment}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

export default Sites;
