import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import AdminLoader from "../../components/AdminLoader";
import { APICALL } from "../../../helper/api/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toastifyError, toastifySuccess } from "../../../helper/Utility";

const InspectionList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { siteId, registerId, registerName } = location.state || {};
  const [loading, setLoading] = useState(false);
  const [inspectionList, setInspectionList] = useState([]);
  const [headers, setHeaders] = useState([]);

  const ExcludedKeys = [];
  // Different cases for excluded keys based on registerId
  if (registerId === "6711f70f6879ffa90927dbab") {
    //Lifting & Rigging
    ExcludedKeys.push(
      "userId",
      "size",
      "expiryDate",
      "location",
      "makeAndModel",
      "testFrequencyQA",
      "lastTestDate",
      "nextTestDue",
      "providerId",
      "quarterStartMonth"
    );
  } else if (registerId === "6711f71c6879ffa90927dbae") {
    //Ladder
    ExcludedKeys.push(
      "userId",
      "expiryDate",
      "testFrequencyQA",
      "swlWll",
      "location",
      "makeAndModel",
      "lastTestDate",
      "nextTestDue",
      "providerId",
      "quarterStartMonth"
    );
  } else if (registerId === "6711f7236879ffa90927dbb1") {
    //Fire
    ExcludedKeys.push(
      "userId",
      "testFrequencyQA",
      "makeAndModel",
      "lastTestDate",
      "licenceNumber",
      "swlWll",
      "nextTestDue",
      "inspectionType",
      "quarterStartMonth",
      "providerId"
    );
  } else if (registerId === "6711f72b6879ffa90927dbb4") {
    //Electrical
    ExcludedKeys.push(
      "userId",
      "swlWll",
      "manufacturer",
      "size",
      "expiryDate",
      "testFrequencyQA",
      "nextTestDue",
      "nextTestDue",
      "location",
      "providerId",
      "quarterStartMonth"
    );
  }

  const [monthKey, setMonthKey] = useState("1");

  const getInspectionList = async () => {
    setLoading(true);
    try {
      const res = await APICALL(
        "/admin/getInspectionsBySiteAndRegister",
        "Post",
        { siteId, registerId }
      );
      if (res?.status && res?.data?.length > 0) {
        const additionalExcludedKeys = [
          "_id",
          "siteId",
          "registerId",
          "status",
          "createdAt",
          "updatedAt",
          "__v",
        ];

        const allExcludedKeys = [...ExcludedKeys, ...additionalExcludedKeys];
        setHeaders(
          Object.keys(res?.data[0]).filter(
            (key) => !allExcludedKeys.includes(key)
          )
        );
        setInspectionList(res?.data);
        setMonthKey(res?.startMonth);
      } else {
        const inspectionFields = [
          "itemDescription",
          "assetNumber",
          "swlWll",
          "manufacturer",
          "licenceNumber",
          "inspectionDate",
          "result",
          "qtagNumber",
          "size",
          "expiryDate",
          "location",
          "makeAndModel",
          "testFrequencyQA",
          "lastTestDate",
          "nextTestDue",
          "inspectionType",
          "inspectorName",
        ];

        setMonthKey(res?.startMonth);
        const filteredFields = inspectionFields.filter(
          (field) => !ExcludedKeys.includes(field)
        );
        setHeaders(filteredFields);
      }
      setLoading(false);
    } catch (error) {
      const inspectionFields = [
        "itemDescription",
        "assetNumber",
        "swlWll",
        "manufacturer",
        "licenceNumber",
        "inspectionDate",
        "result",
        "qtagNumber",
        "size",
        "expiryDate",
        "location",
        "makeAndModel",
        "testFrequencyQA",
        "lastTestDate",
        "nextTestDue",
        "inspectionType",
        "inspectorName",
      ];

      const filteredFields = inspectionFields.filter(
        (field) => !ExcludedKeys.includes(field)
      );
      setHeaders(filteredFields);

      console.error("Error fetching inspection data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getInspectionList();
  }, []);

  const getQuarterInfo = (dateInput) => {
    const date = new Date(dateInput);
    if (isNaN(date)) {
      throw new Error("Invalid date format");
    }

    const month = date.getMonth(); // 0-based (0 = January, 11 = December)
    let quarterInfo = { quarter: "", color: "", textColor: "" };

    for (let i = 0; i < quartersMonths.length; i++) {
      const quarterMonths = quartersMonths[i];

      // Check if the current month is in the current quarter
      if (quarterMonths.some((monthObj) => monthObj.key - 1 === month)) {
        // Assign quarter name and color dynamically based on the quarter index
        switch (i) {
          case 0:
            quarterInfo = {
              quarter: "1st Quarter",
              color: "#f00",
              textColor: "white",
            }; // Red
            break;
          case 1:
            quarterInfo = {
              quarter: "2nd Quarter",
              color: "#0f0",
              textColor: "black",
            }; // Green
            break;
          case 2:
            quarterInfo = {
              quarter: "3rd Quarter",
              color: "#4a86e8",
              textColor: "white",
            }; // Blue
            break;
          case 3:
            quarterInfo = {
              quarter: "4th Quarter",
              color: "#ff0",
              textColor: "black",
            }; // Yellow
            break;
          default:
            quarterInfo = { quarter: "", color: "", textColor: "" };
            break;
        }
        break;
      }
    }

    return quarterInfo;
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const deleteItem = (id, type) => {
    setSelectedId(id);
    setSelectedType(type);
    setDeleteModal(true);
  };

  const handleDeleteSuccess = () => {
    getInspectionList();
  };

  const navigateInspectionHistory = (
    inspectionId,
    qtagNumber,
    siteId,
    registerId,
    registerName
  ) => {
    navigate("/admin/inspection-history/", {
      state: {
        id: inspectionId,
        qtagNumber: qtagNumber,
        siteId: siteId,
        registerId: registerId,
        registerName: registerName,
      },
    });
  };

  const handleInputChange = (index, header, value) => {
    const updatedInspectionList = [...inspectionList];
    const updatedItem = { ...updatedInspectionList[index], [header]: value };
    // Handle changes to inspectionType
    if (header === "inspectionType") {
      updatedItem[header] = value;

      const inspectionDate = new Date(updatedItem.inspectionDate);

      if (!isNaN(inspectionDate)) {
        if (value === "Yearly") {
          // Add 1 year for yearly inspections
          const nextInspection = new Date(
            inspectionDate.setFullYear(inspectionDate.getFullYear() + 1)
          );
          updatedItem.nextInspection = nextInspection
            .toISOString()
            .split("T")[0];
        } else if (value === "Quarterly") {
          // Add 3 months for quarterly inspections
          const nextInspection = new Date(
            inspectionDate.setMonth(inspectionDate.getMonth() + 3)
          );
          updatedItem.nextInspection = nextInspection
            .toISOString()
            .split("T")[0];
        }

        // Apply color coding for the calculated next inspection date
        const { color, textColor } = getQuarterInfo(updatedItem.nextInspection);
        updatedItem.backgroundColor = color;
        updatedItem.InputColor = textColor;
      }

      // Save changes and trigger blur logic
      updatedInspectionList[index] = updatedItem;

      // Save the updated list
      setInspectionList(updatedInspectionList);
      handleInputBlur(index, header, value, updatedItem._id);
      return;
    }

    // Handle changes to inspectionDate
    if (header === "inspectionDate") {
      const inspectionDate = new Date(value);

      if (!isNaN(inspectionDate)) {
        // Add 3 months to calculate next inspection date
        const nextInspection = new Date(
          inspectionDate.setMonth(inspectionDate.getMonth() + 3)
        );
        updatedItem.nextInspection = nextInspection.toISOString().split("T")[0];

        // Apply color coding for the new next inspection date
        const { color, textColor } = getQuarterInfo(updatedItem.nextInspection);
        updatedItem.backgroundColor = color;
        updatedItem.InputColor = textColor;
      }
    }

    // Update the inspection list and save changes
    updatedInspectionList[index] = updatedItem;
    setInspectionList(updatedInspectionList);

    // Trigger blur logic to save or validate data
    handleInputBlur(index, header, value, updatedItem._id);
  };

  const [rows, setRows] = useState(
    [...Array(1)].map(() => ({
      siteId: siteId,
      registerId: registerId,
      itemDescription: "",
      assetNumber: "",
      inspectionDate: "",
      nextInspection: "",
      qtagNumber: "",
      swlWll: "",
      manufacturer: "",
      licenceNumber: "",
      result: "Pending",
      size: "",
      expiryDate: "",
      makeAndModel: "",
      testFrequencyQA: "",
      inspectionType:
        registerId === "6711f7236879ffa90927dbb1"
          ? "HalfYearly"
          : registerId === "6711f71c6879ffa90927dbae"
          ? "Quarterly"
          : "Yearly",
      errors: {},
    }))
  );

  const handleNewInputChnage = (index, e) => {
    const { name, value } = e.target;
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [name]: value,
    };

    // Check if inspection type is changed
    if (name === "inspectionType") {
      updatedRows[index].showQuarterlyMonth = value === "Quarterly";
    }

    setRows(updatedRows);
  };

  const validateFields = (row) => {
    const requiredFields = [];
    if (registerId === "6711f72b6879ffa90927dbb4") {
      const requiredFields = [
        "itemDescription",
        "qtagNumber",
        "inspectionType",
      ];
    } else {
      const requiredFields = [
        "itemDescription",
        "inspectionDate",
        "qtagNumber",
        "inspectionType",
      ];
    }

    const newErrors = {};
    requiredFields.forEach((field) => {
      if (!row[field]) {
        newErrors[field] = `${field.replace(/([A-Z])/g, " $1")} is required`;
      }
    });

    return newErrors;
  };

  const submitData = async (index) => {
    const currentRow = rows[index];
    const errors = validateFields(currentRow);

    // Validate fields
    if (Object.keys(errors).length > 0) {
      const updatedRows = [...rows];
      updatedRows[index].errors = errors;
      setRows(updatedRows);
      return; // Stop submission if validation fails
    }

    try {
      // API call to submit the current row data
      const response = await APICALL(
        `/admin/addOrUpdateInspection/`,
        "POST",
        currentRow
      );

      if (response.status) {
        console.log("Data submitted successfully", response.data);
        setInspectionList((prevList) => [...prevList, response.data]);
        const updatedRows = [...rows];
        updatedRows[index] = {
          ...updatedRows[index],
          itemDescription: "",
          assetNumber: "",
          inspectionDate: "",
          nextInspection: "",
          qtagNumber: "",
          swlWll: "",
          manufacturer: "",
          licenceNumber: "",
          result: "Pending",
          size: "",
          expiryDate: "",
          makeAndModel: "",
          testFrequencyQA: "",
          inspectionType: "",
          errors: {},
        };
        setRows(updatedRows);
      } else {
        toastifyError("Failed to submit data", response.message);
      }
    } catch (error) {
      toastifyError(
        error?.response?.data?.message || "Something Went Wrong !!"
      );
    }
  };

  const handleInputBlur = async (index, header, value, id) => {
    const updatedItem = { ...inspectionList[index], [header]: value, id: id };

    try {
      const response = await APICALL(
        `/admin/addOrUpdateInspection/`,
        "Post",
        updatedItem
      );
      if (response.status) {
        console.log("Data submitted successfully", response.data);
      } else {
        toastifyError("Failed to submit data", response.message);
      }
    } catch (error) {
      toastifyError(
        error?.response?.data?.message || "Something Went Wrong !!"
      );
    }
  };

  const monthsWithKey = [
    { key: 1, month: "January" },
    { key: 2, month: "February" },
    { key: 3, month: "March" },
    { key: 4, month: "April" },
    { key: 5, month: "May" },
    { key: 6, month: "June" },
    { key: 7, month: "July" },
    { key: 8, month: "August" },
    { key: 9, month: "September" },
    { key: 10, month: "October" },
    { key: 11, month: "November" },
    { key: 12, month: "December" },
  ];

  const [showMonths, setShowMonths] = useState(false);

  const changeMonthValues = (event) => {
    event.stopPropagation();
    setShowMonths(!showMonths);
  };

  const updateMonthValues = async (registerId, siteId, monthId, monthName) => {
    // console.log("registerId", registerId);
    // console.log("siteId", siteId);
    // console.log("monthId", monthId);
    // console.log("monthName", monthName);

    const perms = {
      siteId: siteId,
      registerId: registerId,
      startMonth: monthId,
    };

    try {
      const response = await APICALL(
        `/admin/addOrUpdateQuarter/`,
        "POST",
        perms
      );

      if (response.status) {
        toastifySuccess(response.message);
        getInspectionList();
      } else {
        toastifyError("Failed to submit data", response.message);
      }
    } catch (error) {
      toastifyError(
        error?.response?.data?.message || "Something Went Wrong !!"
      );
    }
  };

  const [quarters, setQuarters] = useState([]);
  const [quartersMonths, setQuartersMonths] = useState([]);
  const calculateQuarters = () => {
    const quartersData = [];
    const newQuartersMonths = [];
    let currentIndex = monthKey - 1;

    for (let i = 1; i <= 4; i++) {
      const quarterMonths = [];
      for (let j = 0; j < 3; j++) {
        quarterMonths.push(monthsWithKey[currentIndex % 12]);
        currentIndex++;
      }
      quartersData.push({ quarter: `${i}`, months: quarterMonths });
      newQuartersMonths.push(quarterMonths);
    }
    setQuarters(quartersData);
    setQuartersMonths(newQuartersMonths);
  };

  useEffect(() => {
    calculateQuarters();
  }, [monthKey]);

  return (
    <>
      <div onClick={(event) => setShowMonths(false)}>
        <Row>
          <Col md={3}>
            <div className="bredcrumb_img">
              <ul className="p-0">
                <li>
                  <Link to="/admin/dashboard">
                    <i className="fa-solid fa-house me-2"></i>Home /
                  </Link>
                </li>
                <li>
                  <Link to="/admin/sites">Inspection List /</Link>
                </li>
                <li>
                  <Link className="active" to="/admin/sites">
                    {registerName}
                  </Link>
                </li>
              </ul>
            </div>
            <Typography className="top_page" variant="h5">
              Inspection List
            </Typography>
          </Col>

          <Col md={9} className="text-end">
            <div className="d-flex align-items-center justify-content-end">
              <Link to="/admin/sites">
                <div className="add_new">
                  <Button className="global_btn mb-3" variant="primary">
                    <i className="fa-solid fa-arrow-left me-2"></i> Back
                  </Button>
                </div>
              </Link>
            </div>
          </Col>

          {registerId === "6711f7236879ffa90927dbb1" ? (
            ""
          ) : (
            <>
              {quarters.map((qtr, index) => (
                <Col md={3} key={index}>
                  <div className="color_badge main_head">
                    <div className={`qt${index + 1}_color qt_box`}></div>
                    {index === 0 ? (
                      <button
                        type="button"
                        onClick={changeMonthValues}
                        className="change_schedule_btn"
                      >
                        Change schedule
                      </button>
                    ) : (
                      ""
                    )}
                    <div>
                      <p>{qtr.quarter} Quarter</p>
                      <p>{qtr.months.map((month) => month.month).join(", ")}</p>
                    </div>
                    {index == 0 ? (
                      <>
                        {showMonths && (
                          <div className="monthsDropdown">
                            <ul>
                              {monthsWithKey.map((item) => (
                                <li
                                  onClick={() =>
                                    updateMonthValues(
                                      registerId,
                                      siteId,
                                      item.key,
                                      item.month
                                    )
                                  }
                                >
                                  {item.month}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </Col>
              ))}
            </>
          )}
        </Row>

        <Paper className="table_samepattern mt-md-2 mt-3">
          <TableContainer>
            {loading ? (
              <AdminLoader />
            ) : (
              <Table className="cutoms-login-artist">
                <TableHead>
                  <TableRow>
                    {headers.map((key) => (
                      <TableCell key={key}>
                        <span
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {key === "swlWll"
                            ? "Swl/Wll"
                            : key.charAt(0).toUpperCase() +
                              key.slice(1).replace(/([A-Z])/g, " $1")}
                        </span>
                      </TableCell>
                    ))}
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {inspectionList.map((inspection, index) => (
                    <TableRow key={inspection._id}>
                      {headers.map((header) => {
                        let inputField;
                        let backgroundColor = "";
                        let textClass = "";
                        let InputColor = "";

                        if (header === "inspectionDate") {
                          if (inspection[header]) {
                            const { color, textColor } = getQuarterInfo(
                              inspection[header]
                            );
                            if (registerId === "6711f7236879ffa90927dbb1") {
                              backgroundColor = "";
                              InputColor = "";
                            } else {
                              backgroundColor = color;
                              InputColor = textColor;
                            }
                          }
                        }

                        if (header === "nextInspection") {
                          if (registerId === "6711f72b6879ffa90927dbb4") {
                            if (inspection[header]) {
                              if (inspection["inspectionType"] === "Yearly") {
                                backgroundColor = "black";
                                InputColor = "White";
                              } else {
                                const { color, textColor } = getQuarterInfo(
                                  inspection[header]
                                );
                                backgroundColor = color;
                                InputColor = textColor;
                              }
                            }
                          } else {
                            if (registerId === "6711f7236879ffa90927dbb1") {
                              backgroundColor = "#DBCF34";
                              InputColor = "black";
                            } else {
                              if (inspection[header]) {
                                const { color, textColor } = getQuarterInfo(
                                  inspection[header]
                                );
                                backgroundColor = color;
                                InputColor = textColor;
                              }
                            }
                          }
                        }

                        if (header === "result") {
                          textClass =
                            inspection[header] === "Pass"
                              ? "text-success"
                              : inspection[header] === "Pending"
                              ? "orangeColor"
                              : "text-danger";
                        }

                        if (header === "nextInspection") {
                          inputField = (
                            <span>
                              {inspection[header]
                                ? new Date(inspection[header])
                                    .toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "numeric",
                                    })
                                    .replace(",", "")
                                : "N/A"}
                            </span>
                          );
                        } else if (
                          header === "result" ||
                          header === "inspectorName" ||
                          header === "qtagNumber"
                        ) {
                          inputField = (
                            <span>{inspection[header] || "N/A"}</span>
                          );
                        } else if (header === "inspectionType") {
                          inputField = (
                            <select
                              value={inspection[header] || ""}
                              onChange={(e) =>
                                handleInputChange(index, header, e.target.value)
                              }
                              onBlur={(e) =>
                                handleInputBlur(
                                  index,
                                  header,
                                  e.target.value,
                                  inspection?._id
                                )
                              }
                              disabled={true}
                            >
                              {registerId !== "6711f71c6879ffa90927dbae" ? (
                                <option value="Yearly">Yearly</option>
                              ) : (
                                ""
                              )}
                              <option value="Quarterly">Quarterly</option>
                            </select>
                          );
                        } else if (
                          header === "inspectionDate" ||
                          header === "nextInspection" ||
                          header === "expiryDate" ||
                          header === "lastTestDate" ||
                          header === "nextTestDue"
                        ) {
                          const dateValue = inspection[header]
                            ? new Date(inspection[header])
                                .toISOString()
                                .split("T")[0]
                            : "";
                          inputField = (
                            <input
                              type="date"
                              style={{
                                background:
                                  header === "nextInspection"
                                    ? inspection.backgroundColor ||
                                      "transparent"
                                    : "transparent",
                                border: "none",
                                color:
                                  header === "nextInspection"
                                    ? inspection.InputColor || "black"
                                    : InputColor,
                              }}
                              value={dateValue}
                              onChange={(e) =>
                                handleInputChange(index, header, e.target.value)
                              }
                              onBlur={(e) =>
                                handleInputBlur(
                                  index,
                                  header,
                                  e.target.value,
                                  inspection?._id
                                )
                              }
                            />
                          );
                        } else {
                          inputField = (
                            <input
                              type="text"
                              className="textInput"
                              defaultValue={inspection[header] || ""}
                              onChange={(e) =>
                                handleInputChange(index, header, e.target.value)
                              }
                              onBlur={(e) =>
                                handleInputBlur(
                                  index,
                                  header,
                                  e.target.value,
                                  inspection?._id
                                )
                              }
                            />
                          );
                        }

                        return (
                          <TableCell
                            key={header}
                            className={textClass}
                            style={{
                              backgroundColor: backgroundColor,
                              color: InputColor,
                            }}
                          >
                            {inputField}
                          </TableCell>
                        );
                      })}
                      <TableCell align="right">
                        <Button
                          className="me-2"
                          onClick={() =>
                            navigateInspectionHistory(
                              inspection?._id,
                              inspection?.qtagNumber,
                              inspection?.siteId,
                              inspection?.registerId,
                              inspection?.registerName
                            )
                          }
                        >
                          <i className="fa-solid fa-eye"></i>
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() =>
                            deleteItem(inspection?._id, "inspection")
                          }
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}

                  {rows.map((row, index) => (
                    <TableRow key={`row-${index}`}>
                      {headers.map((header) => (
                        <TableCell key={header}>
                          {header === "result" ||
                          header === "nextInspection" ||
                          header === "inspectorName" ? (
                            <span>-</span>
                          ) : header === "inspectionDate" ||
                            header === "expiryDate" ||
                            header === "lastTestDate" ||
                            header === "nextTestDue" ? (
                            <input
                              type="date"
                              name={header}
                              className="form-control"
                              value={row[header]}
                              onChange={(e) => handleNewInputChnage(index, e)}
                            />
                          ) : header === "inspectionType" ? (
                            <select
                              name={header}
                              className="form-control"
                              value={row[header]}
                              onChange={(e) => handleNewInputChnage(index, e)}
                            >
                              {registerId !== "6711f71c6879ffa90927dbae" ? (
                                <option value="Yearly">Yearly</option>
                              ) : (
                                ""
                              )}
                              <option value="Quarterly">Quarterly</option>
                            </select>
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              name={header}
                              placeholder={`Enter ${
                                header.charAt(0).toUpperCase() +
                                header.slice(1).replace(/([A-Z])/g, " $1")
                              }`}
                              value={row[header]}
                              onChange={(e) => handleNewInputChnage(index, e)}
                            />
                          )}
                          <br />
                          {row.errors[header] && (
                            <span style={{ color: "red" }}>
                              {row.errors[header]}
                            </span>
                          )}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Button
                          variant="success"
                          onClick={() => submitData(index)}
                        >
                          Add
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Paper>

        {deleteModal && (
          <ConfirmationModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            id={selectedId}
            type={selectedType}
            onDeleteSuccess={handleDeleteSuccess}
          />
        )}
      </div>
    </>
  );
};

export default InspectionList;
